import { DatePipe, KeyValuePipe, NgClass } from '@angular/common';
import { Component, computed, input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'dd-validation-errors',
    standalone: true,
    imports: [NgClass, KeyValuePipe, TranslateModule, DatePipe],
    templateUrl: './validation-errors.component.html',
    styleUrls: ['./validation-errors.component.scss'],
})
export class ValidationErrorsComponent {
    public dirty = input.required<boolean>();
    public invalid = input.required<boolean>();
    public errors = input.required<ValidationErrors>();
    public showErrorIcon = input<boolean>(false);

    public isVisible = computed(() => this.dirty() && this.invalid());
    public hasSingleError = computed(
        () => Object.keys(this.errors()).length === 1,
    );
}
