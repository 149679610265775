import {
    IServerSideGetRowsRequest,
    LoadSuccessParams,
} from '@ag-grid-community/core';
import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
    HttpParams,
    HttpResponse,
} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {SimpleContract} from '@app/insurance/interfaces/simple-contract.interface';
import {
    ContractAutocompletedSerialNumber,
    ContractExpired,
} from '@app/service-bike-banner/interfaces/service-bike-banner.interface';
import {FILENAME_MATCH, FILENAME_REPLACE1} from '@core/constants/regex';
import {ApplicationError, ServerError} from '@core/exceptions/error';
import {saveAs} from '@core/helpers/file';
import {LoginMode} from '@core/services/auth/auth-settings.service';
import {SkipModuleUrl} from '@core/services/http/api.interceptor';
import {ContractFactory} from '@core/services/mercator/contract.factory';
import {ProfileService} from '@core/services/profile/profile.service';
import {MODULE} from '@core/tokens/module.token';
import {Factory} from '@shared/factory';
import {Contract, EndOfContractStatus} from '@shared/models/contract';
import {Page} from '@shared/models/page';
import {Status} from '@shared/models/status';
import {TechnicalServiceModality} from '@shared/models/technical-service';
import {ToastrService} from 'ngx-toastr';
import {Observable, catchError, map, of, tap, throwError} from 'rxjs';

export interface ContractListRequest {
    pageSize: number;
    page: number;
    order_by?: string;
    order?: string;
    status_id?: number;
    companyId?: number | string;
    search?: string;
    serviceModality?: TechnicalServiceModality;
    user_id?: number;
}

export interface ContractFilterRequest {
    pageSize?: number;
    page?: number;
    order_by?: string;
    order?: string;
    order_id?: number;
    offer_id?: number;
    contract_id?: number;
    email?: string;
    status_id?: number;
    companyId?: number | string;
    supplier_id?: number;
}

export interface ExportSettings {
    format: string;
    target: string;
    filter?: { role: string; user_role_id: number };
    exportChildCompanies?: boolean;
}

@Injectable({providedIn: 'root'})
export class ContractsService {
    private baseUrl = `${this.module}/contracts`;

    constructor(
        private httpClient: HttpClient,
        private contractFactory: ContractFactory,
        private profileService: ProfileService,
        private toastrService: ToastrService,
        @Inject(MODULE) private module: LoginMode,
    ) {
    }

    public getPaginatedContracts(
        request: ContractListRequest,
    ): Observable<Page<Contract>> {
        const params = new HttpParams({
            fromObject: this.transformRequestToQuery(request),
        });
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        let url = 'contracts';
        if (this.module === 'system') {
            url = `system/${url}`;
        }
        return this.httpClient
            .get<Page<Contract>>(url, {params, headers})
            .pipe(
                catchError((error) => {
                    const handledError = this.handleError(error);
                    return throwError(() => handledError);
                }),
                map((response) => {
                    const page = new Factory<Page<Contract>>(Page).fromObject(
                        response,
                    );
                    page.data = new Factory(Contract).fromArray(page.data);
                    return page;
                }),
            );
    }

    public getFilteredContracts(
        request: ContractFilterRequest,
    ): Observable<Page<Contract>> {
        const params = new HttpParams({
            fromObject: this.transformRequestToQuery(request),
        });
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        let url = 'contracts/filter';
        if (this.module === 'system') {
            url = `system/${url}`;
        }
        return this.httpClient
            .get<Page<Contract>>(url, {params, headers})
            .pipe(
                catchError((error) => {
                    const handledError = this.handleError(error);
                    return throwError(() => handledError);
                }),
                map((response) => {
                    const page = new Factory<Page<Contract>>(Page).fromObject(
                        response,
                    );
                    page.data = new Factory(Contract).fromArray(page.data);
                    return page;
                }),
            );
    }

    public getAllOpenContracts(
        request: ContractFilterRequest,
    ): Observable<Contract[]> {
        const params = new HttpParams({
            fromObject: this.transformRequestToQuery(request),
        });
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        let url = 'contracts/filterOpen';
        if (this.module === 'system') {
            url = `system/${url}`;
        }
        return this.httpClient.get<Contract[]>(url, {params, headers}).pipe(
            catchError((error) => {
                const handledError = this.handleError(error);
                return throwError(() => handledError);
            }),
            map((response) =>
                new Factory<Contract[]>(Array).fromObject(response),
            ),
        );
    }

    public getStatuses(): Observable<Status[]> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        let url = `contracts/statuses`;
        if (this.module === 'system') {
            url = `system/${url}`;
        }
        return this.httpClient.get<Status[]>(url, {headers}).pipe(
            map((res) => res),
            catchError((error) => throwError(() => error)),
        );
    }

    public searchContract(contractNumber: string): Observable<Contract> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        const url = `${this.baseUrl}/search?number=${contractNumber}`;
        return this.httpClient
            .get<Contract>(url, {headers})
            .pipe(map((contract) => this.contractFactory.fromObject(contract)));
    }

    public getContract(id: number | string): Observable<Contract> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        return this.httpClient
            .get<Contract>(`${this.baseUrl}/${id}`, {headers})
            .pipe(map((contract) => this.contractFactory.fromObject(contract)));
    }

    public cancelContract(data: Contract, link: string): Observable<Contract> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        return this.httpClient
            .post<Contract>(link, data, {headers})
            .pipe(map((contract) => this.contractFactory.fromObject(contract)));
    }

    public companyBuyout(contract: Contract): Observable<Contract> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        return this.httpClient
            .post(
                `portal/contracts/${contract.id}/company-buyout`,
                {},
                {headers},
            )
            .pipe(map((contract) => this.contractFactory.fromObject(contract)));
    }

    public update(contract: Contract): Observable<Contract> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        return this.httpClient
            .patch(`${this.baseUrl}/${contract.id}`, contract, {headers})
            .pipe(
                map((updatedContract) =>
                    new Factory(Contract).fromObject(updatedContract),
                ),
            );
    }

    public updateEndDate(contract: Contract): Observable<Contract> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        return this.httpClient
            .post(`${this.baseUrl}/${contract.id}/end-date`, contract, {
                headers,
            })
            .pipe(
                map((updatedContract) =>
                    new Factory(Contract).fromObject(updatedContract),
                ),
            );
    }

    public updateSerialNumber(contract: Contract): Observable<Contract> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        return this.httpClient
            .post(`${this.baseUrl}/${contract.id}/serial-number`, contract, {
                headers,
            })
            .pipe(
                map((updatedContract) =>
                    new Factory(Contract).fromObject(updatedContract),
                ),
            );
    }

    public export(exportSettings: ExportSettings): Observable<boolean> {
        let url = 'contracts/export';
        if (this.module === 'system') {
            url = `system/${url}`;
        }
        return this.httpClient
            .post(
                url,
                {exportSettings: exportSettings},
                {
                    responseType: 'blob',
                    headers: {
                        Accept: 'application/json; charset=utf-8',
                        'Content-Type': 'application/json; charset=utf-8',
                        'X-Skip-Module-Url': '',
                    },
                    observe: 'response',
                },
            )
            .pipe(
                tap((res: HttpResponse<Blob>) => {
                    const content = res.headers.get('content-disposition');
                    if (content) {
                        const filename = content
                            .match(FILENAME_MATCH)[1]
                            .replace(new RegExp(FILENAME_REPLACE1), '');
                        saveAs(res.body, filename);
                    }
                }),
                map(() => true),
                catchError(() => {
                    this.toastrService.error('Der Export ist fehlgeschlagen');
                    return of(false);
                }),
            );
    }

    private transformRequestToQuery(
        request: ContractListRequest | ContractFilterRequest,
    ): { [key: string]: string } {
        const transformedObject: { [p: string]: string } = {};
        Object.keys(request).forEach((key) => {
            if (request[key]) {
                transformedObject[key] = request[key].toString();
            }
        });
        transformedObject['global_view'] = String(
            this.profileService.global_view,
        );
        return transformedObject;
    }

    private handleError(response: HttpErrorResponse): ApplicationError {
        if (response instanceof HttpErrorResponse) {
            return new ServerError();
        } else {
            return response;
        }
    }

    public getAgGridData(
        request: IServerSideGetRowsRequest,
    ): Observable<LoadSuccessParams> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        const params = new HttpParams({
            fromObject: {
                globalView: String(this.profileService.global_view),
            },
        });
        let url = 'contracts/aggrid';
        if (this.module === 'system') {
            url = `system/${url}`;
        }
        return this.httpClient
            .post<LoadSuccessParams>(url, request, {params, headers})
            .pipe(
                catchError((error) => {
                    const handledError = this.handleError(error);
                    return throwError(() => handledError);
                }),
            );
    }

    public getContractsAboutToExpire(): Observable<ContractExpired[]> {
        const url = 'employee/contracts/about-to-expire';
        const headers = new HttpHeaders().set(SkipModuleUrl, '');

        return this.httpClient.get<ContractExpired[]>(url, {
            headers,
        });
    }

    public updateEndOfContractState(
        contractId: number,
        status: EndOfContractStatus,
    ): Observable<Contract> {
        const url = `employee/contracts/update-end-of-contract-state/${contractId}`;
        const headers = new HttpHeaders().set(SkipModuleUrl, '');

        return this.httpClient.post<Contract>(url, {status}, {headers});
    }

    public getContractsByAutocompletedSerialNumber(): Observable<
        ContractAutocompletedSerialNumber[]
    > {
        const url = 'employee/contracts/autocompleted-serial-number';
        const headers = new HttpHeaders().set(SkipModuleUrl, '');

        return this.httpClient.get<ContractAutocompletedSerialNumber[]>(url, {
            headers,
        });
    }

    public getActiveContractsForUser(
        userId: number,
    ): Observable<SimpleContract[]> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        const url = `contracts/users/${userId}/contracts/existing-support-cases`;
        return this.httpClient
            .get<{ payload: SimpleContract[] }>(url, {headers})
            .pipe(map((data) => data.payload));
    }

    public getAllContractsForUser(
        userId: number,
    ): Observable<SimpleContract[]> {
        const headers = new HttpHeaders().set(SkipModuleUrl, '');
        const url = `contracts/${userId}`;
        return this.httpClient
            .get<{ payload: SimpleContract[] }>(url, {headers})
            .pipe(map((data) => data.payload));
    }
}
