// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    production: false, // important to catch & send errors
    apiUrl: 'https://api.sandbox.deutsche-dienstrad-plattform.de',
    name: 'benefit-portal',
    version: '3.8.0',
    calculatorUrl: 'https://dienstradrechner.deutsche-dienstrad-plattform.de',
    calculatorClientId: 'sandbox',
};
